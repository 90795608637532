import Vue from 'vue'

// 防重复点击
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.style.pointerEvents = 'none'
        setTimeout(() => {
          el.style.pointerEvents = 'auto'
        }, binding.value || 1000)
      }
    })
  }
})
