import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)
 
export default new Vuex.Store({
    state: {
        token: '',
        loginStatus:0,/* 登陆状态 */
        userInfo: '',/* 用户信息 */
        unionid:"",
        activityQrcodeUrl:''//跳转的海报页地址
    },
    mutations: {
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo
        },
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_UNIONID: (state, unionid) => {
            state.unionid = unionid
        },
    },
    getters: {},
    actions: {
        Set_UserInfo({commit}){
            let userInfo = window.localStorage.getItem('userInfo')
            commit('SET_USERINFO',userInfo)
        },
        Set_Token({commit}){
            let token = window.localStorage.getItem('token')
            commit('SET_TOKEN',token)
        },
        Set_Unionid({commit}){
            let unionid = window.localStorage.getItem('unionid')
            commit('SET_UNIONID',unionid)
        },
        Logout({commit}){
            window.localStorage.removeItem('userInfo')
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('unionid')
            commit('SET_TOKEN','')
            commit('SET_USERINFO','')
            commit('SET_UNIONID','')
        }
    },
    modules: {}
})