import Vue from 'vue'
import VueRouter from 'vue-router'
import dwCoupon from '@/view/dwCoupon.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
    path: '/',
    name: 'dwCoupon',
/*     meta: {
      title: "懂哇优惠券，等你来领取哦…"
    }, */
    component: dwCoupon
  },
  {
    path: '/dwCoursesDetail',
    name: 'dwCoursesDetail',
    component: () => import('@/view/dwCoursesDetail.vue'),
    /*       meta:{
          title:"懂哇全科大优惠，等你来领取哦…"
        } */
  },
  {
    path: '/dwDownload',
    name: 'dwDownload',
    meta:{
      title:"懂哇-支付成功"
    },
    component: () => import('@/view/dwDownload.vue')
  },
  {
    path: '/wxPrompt',
    name: 'wxPrompt',
    meta: {
      title: "懂哇-支付成功"
    },
    component: () => import('@/view/wxPrompt.vue')
  },
  {
    path: '/dwAllCoupon',
    name: 'dwAllCoupon',
    meta: {
      title: "优惠券列表"
    },
    component: () => import('@/view/dwAllCoupon.vue')
  },
  {
    path: '/sharePoster',
    name: 'sharePoster',
    meta: {
      title: "分享海报"
    },
    component: () => import('@/view/sharePoster.vue')
  },
  {
    path: '/userLoginBinding',
    name: 'userLoginBinding',
    meta: {
      title: "登录/绑定"
    },
    component: () => import('@/view/userLoginBinding.vue')
  },
]
const router = new VueRouter({
  routes,
  mode:'hash',
})

export default router