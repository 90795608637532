import request from '@/utils/request'


/* 查询优惠券 */
export const searchCoupon =id=>{
    return request({
        url:`/api/v1/gl/coupon/getById?id=${id}`,
        method:"get"
    })  
}

/* 优惠券领取 */
export const getCoupon =couponId=>{
    return request({
        url:`/api/v1/gl/coupon/user/receiveCoupon?couponId=${couponId}`,
        method:"put",
    })  
}


/* 商品详情 */
export const goodDetail =id=>{
    return request({
        url:`/api/v1/gl/product/getById?id=${id}`,
        method:"get",
    })  
}

/* 优惠券记录 */
export const couponList =data=>{
    return request({
        url:`/api/v1/gl/coupon/list`,
        method:"post",
        data
    })  
}