import request from '@/utils/request'

/*支付订单创建*/
export const createOrder =data=>{
    return request({
        url:`/api/v1/gl/product/productCommitOrder`,
        method:"post",
        data
    })  
} 

/*支付协议*/
export const protocol =id=>{
    return request({
        url:`/api/v1/glProtocolTypeDetail/getById?id=${id}`,
        method:"get",
    })  
} 

/*查询已购的订单*/
export const orderStatus =id=>{
    return request({
        url:`/api/v1/gl/product/findBuyProductOrder?productId=${id}`,
        method:"get",
    })  
} 
