import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant' // 组件库
import 'vant/lib/index.css' // 样式
import store from './store'
import 'amfe-flexible'
import wechat from '@/utils/wxjsdk'
import VueTouch from 'vue-touch'
Vue.use(VueTouch, {
  name: 'v-touch'
})
// 自定义指令
import './directives/preventReClick'
Object.assign(Vue.prototype, {
  '$wechat': wechat
})
/* import Vconsole from 'vconsole'
let vConsole = new Vconsole()  */
import {Toast} from "vant";
Vue.use(Toast);
Vue.config.productionTip = false
Vue.use(Vant)
/* Vue.use(vConsole) */
var sensors = require('sa-sdk-javascript');
let title = null 
router.beforeEach((to, from, next) => { 
	if(to.name === 'dwCoupon'){
    title = to.query.title || window.localStorage.getItem('couponTitle')
    window.localStorage.setItem('couponTitle',title)
  }else if(to.name === 'dwCoursesDetail'){
    title = window.localStorage.getItem('goodTitle')
  }
	next() 
}) 
sensors.init({
  server_url: 'https://fenzhidao.datasink.sensorsdata.cn/sa?project=production&token=92c45f523c806f01',
  is_track_single_page: false, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  show_log: false,
  send_type: 'beacon',
  scrollmap:{
    collect_url:function(){
      if(['/','dwCoursesDetail'].includes(router.currentRoute.path)){
        return true
      }
    }
  },
  heatmap: {
    //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap:'not_collect',
    //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map:'not_collect'
} 
});
/*  sensors.quick('autoTrack'); //用于采集 $pageview 事件 */
Vue.prototype.$sensors = sensors
sensors.registerPropertyPlugin({
  isMatchedWithFilter:function(data){
      return data.event === "$WebStay";
  },
  properties:function(data){
      data.properties['$title'] = title;
  }
});
sensors.registerPage({
  platform_type: 'H5_懂哇优惠券',
});
new Vue({
  store,
  render: h => h(App),
  router,
}).$mount('#app')
