import request from '@/utils/request'


/* 获取数广JS-SDK配置参数*/
export const getConofigParams =params=>{
    return request({
        url:`/api/v1/gl/h5/obtainConfigParam`,
        method:"get",
        params
    })  
}


/* 微信Code解密*/
export const decryptCode =params=>{
    return request({
        url:`/api/v1/gl/h5/webWxCodeDecode`,
        method:"get",
        params
    })  
}



/* 用户登录*/
export const userLogin =data=>{
    return request({
        url:`/api/v1/gl/h5/wxLogin`,
        method:"post",
        data
    })  
}


/* 用户登录*/
 export const userInfo =params=>{
    return request({
        url:`/api/v1/user/getUserInfo`,
        method:"get",
        params
    })  
} 
