import request from '@/utils/request'

/*获取验证码*/
export const getAuthCode =data=>{
    return request({
        url:`/api/v1/userAction/sendSms`,
        method:"post",
        data
    })  
} 

/*支付后绑定*/
export const setPayPhone =data=>{
    return request({
        url:`/api/v1/gl/product/bindAccessByPhone`,
        method:"post",
        data
    })  
} 

/*下载二维码*/
export const dwQrcode =key=>{
    return request({
        url:`/api/v1/gl/config/getByKey?key=${key}`,
        method:"get",
    })  
} 

/*用户协议信息protocol_context*/
export const getLoginContext =()=>{
    return request({
        url:`/api/v1/glProtocolTypeDetail/getProtocolContext`,
        method:"get",
    })  
} 