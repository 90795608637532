import axios from 'axios'
import router from '@/router/index';
import { Toast } from 'vant'
let path = process.env.NODE_ENV === 'production' ? 'https://dongwa.dowell.vip/gl-user':process.env.NODE_ENV === 'development'?'https://dongwa2.fenzhidao.com/gl-user':'/'
console.log(path,process.env.NODE_ENV)
/* import {encryptByMd5} from '@/utils/encrypt.js' */
const service = axios.create({
  timeout: 30000,
  baseURL: path,
})
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    const authToken = window.localStorage.getItem('token')
    /* 跳过接口验签 */
    config.headers['nonce'] = '2233abcd'
    if (authToken) {
      let timestamp = Math.round(new Date().getTime() / 1000)
      /*  接口验签 接口参数加密 */
      /*         config.params['time'] = time;
              config.headers['sign'] = '02de80498baf11eaa84b0242ac110005'  */
      /*config.headers['protocol'] = "4a43c15cae73a44da348fed75974351c" */
/*       config.headers['device'] = 'H5'
      config.headers['app-version'] = '1.0.0' */
      /* config.headers['Content-Type'] ='application/x-www-form-urlencoded' */
      config.headers['timestamp'] = timestamp
      config.headers['X-Authorization-Token'] = 'Bearer-' + authToken
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data && [-50, 405, 406].includes(response.data.code)) {
      Toast('登录已过期，请重新登录!')
      setTimeout(()=>{
        window.localStorage.removeItem('token') 
        window.localStorage.removeItem('userInfo')
        window.localStorage.removeItem('openid')
        //let couponId = window.localStorage.getItem('couponId')
        router.push({
          //name: 'dwCoupon',query:{id:couponId}
        })
      },1000)
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service