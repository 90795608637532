<template>
    <van-popup v-model="isShow" position="bottom" round :style="{ height: '80%' }" closeable
        close-icon="http://dongwa-1308772967.cos.ap-guangzhou.myqcloud.com/dongwa/file/upload/202306/2023-06-07_16861017820891666257759483465728.png"
        close-icon-position="top-right" :safe-area-inset-bottom="false">
        <div class="content">
            <div class="title-wrap">
                <div class="left-text" @click="isShow=false">我已阅读</div>
                <div class="t-icon">
                    <img src="../../assets/img/t_left.png" alt="">
                </div>
                <div class="title">
                    {{proType === 0?'服务协议':loginProType === 1?'用户登录协议':'用户隐私协议'}}
                </div>
                <div class="t-icon">
                    <img src="../../assets/img/t_right.png" alt="">
                </div>
            </div>
            <div class="content-text" v-html="protocolText">

            </div>
        </div>
    </van-popup>
</template>

<script>
import { protocol } from '@/api/pay'
import {getLoginContext} from '@/api/user'
export default {
    components: {},
    data() {
        return {
            isShow: false,
            protocolText: "",
            loginProType:null
        }
    },
    props:{
        proType:{
            type:Number,
            define:0
        },
    },
    methods: {
        show(type) {
            this.isShow = true
            this.loginProType = type
            if (this.proType === 0){
                this.getProtocol()
            } else{
                this.getLoginProtocol()
            }
        },
        getProtocol() {
            let id = '76b1770e090611eeb1cdb8cef62825a0'
            protocol(id).then(res => {
                if (res.code === 200) {
                    this.protocolText = res.data
                }
            })
        },
        getLoginProtocol(){
            getLoginContext().then(res=>{
                if(res.code === 200){
                    this.protocolText =this.loginProType  === 1? res.data.serviceProtocol:res.data.privacyProtocol
                }
            })
        }
    },
    created() { }
}
</script>
<style lang="scss" scoped>
.content {
    padding: 10px;
    box-sizing: border-box;
    height: 100%;

    .title-wrap {
        display: flex;
        font-size: 20px;
        font-weight: bold;
        justify-content: center;
        position: relative;

        .left-text {
            position: absolute;
            left: 10px;
            font-size: 16px;
            font-weight: normal;
            top: 12px;
            color: #33A5FF;
        }

        .title {
            margin: 10px 10px;
        }

        .t-icon {
            width: 22px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .content-text {
        font-size: 14px;
        height: 90%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 10px;
        box-sizing: border-box;

    }
}

:deep(table) {
    width: fit-content !important;
    border-collapse: collapse;

    tr:nth-child(1) {
        background-color: green !important;
    }

    th {
        background-color: #f1f1f1;
    }

    tr:not(:first-child) td:nth-child(1) {
        background-color: #ccc;
    }

    td,
    th {
        border: 1px solid #222;
        min-width: 50px;
        height: 20px;
    }

    p {
        text-align: center;
    }
}
</style>
