<template>
  <div class="container">
    <div class="header">
      <div class="img-wrap">
        <img :src="couponData.headImage" alt="">
      </div>
    </div>
    <div class="content">
      <div class="coupon-wrap">
        <div class="conpon-box">
          <div :class="['left', curCouponClass]">
            <div class="price"><span>￥</span>{{ couponData.money }}</div>
            <div class="full-price">满{{ couponData.fullMoney }}元使用</div>
          </div>
          <div class="right">
            <div class="c-text">
              <div class="c-title">{{ couponData.name }}</div>
              <span v-if="couponData.shelvesType===2">{{ couponData.shelvesEndTime }}过期</span>
              <span v-else>有效期:长期有效</span>
            </div>
            <div :class="['btn', curCouponClass]" v-preventReClick="2000" @click.stop="handleReceive">{{ curBtnText }}
            </div>
          </div>
        </div>
        <div class="c-desc">
          <div class="c-desc-tiltle">
            <div></div>
            优惠券须知
          </div>
          <div class="c-desc-text" v-html="couponData.copyWriting"></div>
          <!--           <div class="c-desc-text">2.显示购买须知的内容显示购买须知的内容显示购买须
            知的内容显示购买须知的内容显示购买须知的内容。</div> -->
        </div>
        <div class="wx-login-box fs-14" v-if="userStatus === 0 && loginStatus !== 1">
          <div>使用微信登陆后，领取优惠券！</div>
          <div class="btn" @click="handleLogin" v-preventReClick="2000">微信登录</div>
        </div>
      </div>
    </div>

     <!--注册登录或绑定app弹窗-->
     <van-popup v-model="isShow" position="bottom" round  closeable
        close-icon="http://dongwa-1308772967.cos.ap-guangzhou.myqcloud.com/dongwa/file/upload/202306/2023-06-07_16861017820891666257759483465728.png"
        close-icon-position="top-right" :safe-area-inset-bottom="false">
            <div class="init-layout">
                <div class="title-wrap">
                    <div class="t-icon">
                        <img src="../assets/img/icon_title_tag_left_1.png" alt="">
                    </div>
                    <div class="title">
                      手机号快捷登录
                    </div>
                    <div class="t-icon">
                        <img src="../assets/img/icon_title_tag_right_1.png" alt="">
                    </div>
                </div>
                <div class="tip-text">
                  未注册过的手机号将自动创建懂哇账号
                </div>
                <!--手机号登录-->
                <LoginBinding @hidePopup='loginSuccess' :wxCode="wxCode" :pageStatus=2 ref="LoginBinding" :couponId="couponId"></LoginBinding>
            </div>
        </van-popup>
  </div>
</template>

<script>
import { userInfo } from '@/api/login'
import { searchCoupon, getCoupon } from '@/api/coupon.js'
import {parseTime,signLink,setPageTitle,getUrlCode } from '@/utils'
import LoginBinding from './components/LoginBinding.vue'
import { mapActions } from 'vuex'
export default {
  components: {LoginBinding},
  data() {
    return {
      loginStatus:null,
      userStatus: 0,
      isShow:false,
      couponData: {},
      curCouponClass: "",
      curBtnText: "领取",
      couponId: "",
      title:"",
      wxCode:'',
      defineImg:'https://dongwa-1308772967.cos.ap-guangzhou.myqcloud.com/dongwa/file/upload/202306/2023-06-08_16862324768551666805933201424384.jpg',

    }
  },
  methods: {
    ...mapActions(['Set_UserInfo']),
    handleLogin(){
      this.isShow = true
    },
    handleReceive() {
      if (this.userStatus === 0 && this.loginStatus !==1) {
        this.$toast('点击底部微信登陆按钮后，领取优惠券')
      } else if (this.userStatus === 1 && this.couponData.couponStatus === 0) {
        /* 领取优惠券 */
        this.getCoupons()
      } else {
        const {couponStatus} = this.couponData
        /* 优惠券按钮事件埋点 */
        if([2,1,-1].includes(couponStatus)){
          let id = couponStatus === 2?'EDC5':couponStatus === -1?'EDC4':'EDC3'
          let text = couponStatus === 2?'已使用优惠券':couponStatus === -1?'过期优惠券':'使用优惠券'
          this.$sensors.track("$WebClick",{
            $element_content:`${text}_${this.couponData.name}`,
            custom_element_id:`${id}`,
            $title:this.title
          });
        }
        /* 跳转至商品详情页 */
        let userAgent = navigator.userAgent;
        let goodTitle = `优惠券购买页_${this.couponData.name}`
        let isIOS = /(iPhone|iPad|iPod|iOS|Mac|Mac)/i.test(userAgent)
        if (isIOS) {
            let url = window.location.href.split('#')[0] + `#/dwCoursesDetail?goodId=${this.couponData.productId}`
            window.location.href = url
        } else {
          this.$router.push({ path: '/dwCoursesDetail', query: { goodId: this.couponData.productId } })
        }
        window.localStorage.setItem('goodTitle',goodTitle)
      }
    },
    /* 领取优惠券 */
    getCoupons() {
      getCoupon(this.couponData.id).then(res => {
        if (+res.code === 200) {
          this.$sensors.track("$WebClick",{
              $element_content:`领取优惠券_${this.couponData.name}`,
              custom_element_id:'EDC2',
              $title:this.title
          });
          this.$toast('领取成功!')
          this.getCouponData()
        } else {
          this.$toast(res.message)
        }
      })
    },
    setParseTime(time) {
      return parseTime(time)
    },
    loading(){
      this.$toast.loading({
        message: '加载中...',
        loadingType: 'spinner',
        duration:0
      });
    },
    //登录成功后的操作
    loginSuccess(status){
      this.loginStatus = status
      this.isShow = false
    },
    /* 获取优惠券信息 */
    getCouponData() {
      this.couponId = this.$route.query.id || getUrlCode('id')
      if(this.couponId) window.localStorage.setItem('couponId', this.couponId)
      this.loading()
      searchCoupon(this.couponId).then(res => {
        if (+res.code === 200) {
          this.couponData = res.data
          const { couponStatus } = res.data
          if (couponStatus === 0) {
            this.curCouponClass = ''
            this.curBtnText = '领取'
          } else if (couponStatus === 1) {
            this.curBtnText = '去使用'
            this.curCouponClass = 'use'
          } else if (couponStatus === 2) {
            this.curBtnText = '已使用'
            this.curCouponClass = 'expire'
          } else {
            this.curBtnText = '已过期1111'
            this.curCouponClass = 'expire'
          }
          this.handleShare()
          this.title= `优惠券页_${this.couponData.name}`
          /* 页面埋点 */
          this.$sensors.quick("autoTrack",{
            $title:this.title,
            custom_page_id:'PDC1',
          });
          setPageTitle(res.data.pageName)
        }
      }).finally(()=>{
        this.$toast.clear()
      })
    },
    /* 获取用户信息 */
    getUserInfo() {
      userInfo().then(res => {
        if (res.code === 200) {
          const { headimgUrl, nickname, token, openid } = res.data
          const userInfo = {
            headimgUrl, nickname,userStats:1
          }
          this.setUserData(token, userInfo, openid)
        } else {
          this.userStatus = 0
        }
      })
    },
    /* 设置用户信息 */
    setUserData(token, userInfo, openid) {
        window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
        if(openid) window.localStorage.setItem('openid', openid)
        this.Set_UserInfo()
        if (token) {
            window.localStorage.setItem('token', token)
            this.Set_Token()
        }
    },
    handleShare() {
      let link = signLink()
      const { shareName, shareDesc, shareIcon,name } = this.couponData
      let shareObj = {
        title: shareName || name, // 分享标题
        link, // 分享链接
        desc: shareDesc || name, // 分享描述
        imgUrl: shareIcon||this.defineImg
      }
      this.$wechat.wxShare(shareObj)
    },
  },
  created() {
    if (this.$wechat.isWeixin()) {
      this.userStatus = window.localStorage.getItem('token') ? 1 : 0
      this.wxCode = getUrlCode('code')
      if (this.wxCode && this.userStatus === 0) {
        /* 首次登录 */
        this.isShow = true
      } else if (this.userStatus === 1) {
        this.getUserInfo()
      }
      this.getCouponData()
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;

  .content {
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 100;
    background: #E7EEFE;
    height: calc(100% - 184px);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;

    >div {
      width: 100%;
      height: 100%;
      position: relative;
      padding: 18px;
      box-sizing: border-box;

      .conpon-box {
        display: flex;
        position: relative;
        width: 100%;
        height: 90px;
        margin: 22px auto;
        border-radius: 6px;
        overflow: hidden;

        &::after,
        &::before {
          position: absolute;
          display: inline-block;
          left: 81px;
          content: "";
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #E7EEFE;
        }

        &::after {
          top: -7.5px;
        }

        &::before {
          bottom: -7.5px;
        }
      }
      .conpon-box .left.expire {
          background: linear-gradient(90deg, #CCCCCC 0%, #BFBFBF 100%);
          color: #FFFFFF;
        }
      .conpon-box .left {
        width: 26%;
        background: linear-gradient(225deg, #52C2FF 0%, #9574FF 100%);
        color: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;

        >div {
          text-align: center;
        }

        .price {
          font-size: 26px;

          span {
            font-size: 14px;
          }
        }

        .full-price {
          font-size: 11px;
        }
      }

      .conpon-box .right {
        width: 74%;
        background-color: #fff;
        display: flex;
        align-items: center;

        .c-text {
          flex: 4;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .c-title{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            padding-left: 2px;
          }
          >div {
            font-weight: normal
          }

          span {
            font-size: 12px;
            color: #999999;
            margin-top: 2px;
          }
        }

        .btn {
          flex: 1;
          background: linear-gradient(90deg, #52C2FF 0%, #33A5FF 100%);
          color: #fff;
          font-size: 14px;
          text-align: center;
          padding: 6px;
          margin-right: 10px;
        }

        .btn.use {
          border: 1px solid #33A5FF;
          background: #fff;
          color: #33A5FF;
        }

        .btn.expire {
          background: linear-gradient(90deg, #CCCCCC 0%, #BFBFBF 100%);
          color: #FFFFFF;
        }
      }

      .c-desc {
        font-size: 15px;

        .c-desc-tiltle {
          display: flex;
          align-items: center;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .c-desc-tiltle>div {
          width: 4px;
          height: 10px;
          background: #279FFE;
          border-radius: 3px 3px 3px 3px;
          margin-right: 2px;
        }

        .c-desc-text {
          color: #666666;
          font-size: 14px;
          margin-bottom: 4px;
        }
      }

      /* login box */
      .wx-login-box {
        position: absolute;
        bottom: 50px;
        padding: 8px;
        width: calc(100vw - 36px);
        background: #5e6167;
        border-radius: 10px;
        box-sizing: border-box;
        color: #fff;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        align-items: center;

        .btn {
          color: #33A5FF;
          background-color: #fff;
        }
      }

      /* btn */
      .btn {
        padding: 6px 12px;
        width: fit-content;
        border-radius: 16px;
      }
    }

  }

  .img-wrap {
    width: 100%;
    height: 210px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .content.active {
    height: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .init-layout{
    padding: 0 16px 20px 16px;
    .title-wrap {
        display: flex;
        font-size: 20px;
        font-weight: bold;
        justify-content: center;

        .title {
            margin: 10px 10px;
        }

        .t-icon {
            width: 22px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .tip-text{
        width: 70%;
        margin: 0 auto;
        font-size: 13px;
        color: #888888;
        text-align: center;
        margin-bottom: 30px;
    }
}
}
</style>
