<template>
  <div class="from-wrap">
        <van-cell-group class="cell-wrap">
            <van-field :class="{active:params.account!=''}" maxlength="11" @blur="phoneVerify" center clearable
                v-model="params.account" placeholder="点击输入手机号码" />
            <van-field :class="{active:params.verificationCode!=''}" maxlength="6" v-model="params.verificationCode" center clearable placeholder="请输入验证码">
                <template #button>
                    <span>|</span>
                    <div :class="['code-text', { active: codeDisabeld }]" @click="getCode">{{ content }}</div>
                </template>
            </van-field>
        </van-cell-group>
        <div class="sub-btn" @click="handleSub">
            <div>{{btnText}}</div>
        </div>
        <div class="agreement-wrap">
            <van-checkbox class="dw-checkbox" v-model="isRead"></van-checkbox>
            <div class="text">登录即代表同意 <span class="agreement" @click.stop="$refs.PayProtocol.show(1)">《用户登录协议》</span>及<span class="agreement" @click.stop="$refs.PayProtocol.show(2)">《用户隐私协议》</span>内所有条款</div>
        </div>
        <PayProtocol ref="PayProtocol" :proType=1></PayProtocol>
  </div>
</template>

<script>
import { getAuthCode } from '@/api/user'
import { mapActions } from 'vuex'
import { decryptCode, userLogin } from '@/api/login'
import PayProtocol from './PayProtocol'
export default {
  components:{PayProtocol},
  data () {
    return {
        params:{
            account:'',
            verificationCode:"",
            code: 'LoginConfirmation',
        },
        unionid:"",
        codeDisabeld:false,
        content: '发送验证码',
        btnText:"注册/登录",
        isRead:false,
        totalTime: 60, //具体倒计时时间
        appId: "wx2720a1fa7e99d413",
        redirect_uri: "https://h5.dongwa.vip",
      /*   redirect_uri: "https://h52dongwa.fenzhidao.com", */
        /* 测试appid跟域名 */
      /*   appId:"wx0fd10aace65470c1",
        redirect_uri:"http://192.168.0.246:3002/#/userLoginBinding",  */
    }
  },
  props:{
        rmdId:{
            type:String,
            define:""
        },
        //区分是购买页登录还是海报页未登录 1:海报页未登录 0：购买页登录 2：领取页登录
        pageStatus:{
            type:Number,
            define:0
        },
        //商品id
        goodId:{
            type:String,
            define:""
        },
        //优惠券ling'qu'ye需要用到的couponId
        couponId:{
            type:String,
            define:""
        },
        wxCode:{
            type:String,
            define:""
        }
  },
  methods: {
    ...mapActions(['Set_UserInfo', 'Set_Token']),
    getCode() {
        if (!this.params.account) {
            return this.$toast('请填写手机号')
        }
        if (!this.codeDisabeld) {
            const params = {
                account: this.params.account,
                code: 'LoginConfirmation',
                verificationCode:this.verificationCode
            }
            getAuthCode(params).then(res => {
                if (res.code === 200) {
                    this.codeDisabeld = true
                    this.$toast('发送成功!')
                    this.content = '重新获取 ' + this.totalTime + 's'
                    let timeout = setInterval(() => {
                        this.totalTime--
                        this.content = '重新获取 ' + this.totalTime + 's'
                        if (this.totalTime < 0) {
                            //当倒计时小于0时清除定时器
                            window.clearInterval(timeout)
                            this.content = '重新获取'
                            this.totalTime = 60
                            this.codeDisabeld = false
                        }
                    }, 1000)
                } else {
                    this.$toast(res.message)
                }
            })
        }
    },
    handleSub(){
        if(!this.params.account) return this.$toast('请输入手机号')
        if(!this.params.verificationCode) return this.$toast('请输入验证码')
        if(!this.isRead) return this.$toast('请先勾选并阅读相关协议')
        this.phoneLogin()
    },
    /* 获取code */
    getWxCode() {
        if(this.pageStatus === 1) {
            this.redirect_uri = ''+this.redirect_uri+'/#/userLoginBinding'
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${encodeURIComponent(this.redirect_uri+ '?rmdId=' + this.rmdId+ '')}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
        }else if(this.pageStatus === 0){
            this.redirect_uri = ''+this.redirect_uri+'/#/dwCoursesDetail'
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${encodeURIComponent(this.redirect_uri+ '?rmdId=' + this.rmdId+ '&goodId='+this.goodId+'')}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
        }else{
            this.redirect_uri = ''+this.redirect_uri+''
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${encodeURIComponent(this.redirect_uri+ '?id=' + this.couponId + '')}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
        }
    },
     /* 登录 */
    handleLogin(data) {
        const { nickname, headimgurl,openid,recommendSalesUserId,unionid  } = data
        /* 设置用户属性埋点 */
        this.$sensors.setOnceProfile({
            wechatName:nickname,
        });
        /* 用户事件埋点 */
        this.$sensors.track("$WebClick",{
            custom_element_id:'EDC1',
            $element_content:"微信登录",
            $title:this.title
        });
        const params = {
            unionId:unionid,
            type: 1,
            wxExtraInfo:data,
            openId: openid,
            wxNickName:nickname,
            wxHeadImg: headimgurl,
            code:'LoginConfirmation',
            ...JSON.parse(window.localStorage.getItem('loginData'))
        }
        userLogin(params).then(res => {
            if (res.code === 200) {
                const { token,id,userStats} = res.data
                const userInfo = {
                    headimgurl, nickname,recommendSalesUserId,userStats
                }
                this.$sensors.login(id)
                this.$sensors.setOnceProfile({
                    id_coupon:`${id}`
                });     
                this.setUserData(token, userInfo, openid)          
                if(this.pageStatus === 1){
                    //登录后跳转到海报分享页
                    this.$router.push({path:'sharePoster',query:{rmdId:this.rmdId}})
                }else{
                    //优惠券领取页与购买页登陆成功后隐藏登录弹窗 
                    this.$emit('hidePopup',1)
                }
            }else{
                this.$toast({
                    message:res.message,
                    duration:5000
                })
            }
        })
    },
     /* code解密 */
     handleDecryptCode() {
        return new Promise((resolve, rejects) => {
            decryptCode({ wxCode:this.wxCode }).then(res => {
                if (+res.code === 200) {
                    window.localStorage.setItem('openid', res.data.openid)
                    resolve(res.data)
                } else {
                    rejects(res.message)
                }
            })
        })
    },
    /* 设置用户信息 */
    setUserData(token, userInfo, openid) {
        window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
        if(openid) window.localStorage.setItem('openid', openid)
        this.Set_UserInfo()
        if (token) {
            window.localStorage.setItem('token', token)
            this.Set_Token()
        }
    },
    //手机号注册登录
    phoneLogin(){
        window.localStorage.setItem("loginData", JSON.stringify(this.params));
        this.getWxCode()
    },
    phoneVerify() {
        if (!/^1[3456789]\d{9}$/.test(this.params.account) && this.params.account) {
            return  this.$toast('手机号格式不正确，请重新填写')
        }
    },
  },
  created () {
        if(this.wxCode){
            /* 首次登录缓存信息 */
            this.handleDecryptCode().then(data => {
                this.handleLogin(data)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.from-wrap{
    padding-bottom: 20px;
}
:deep(.van-field){
    border-bottom: 1px solid #EAEAEA;
    margin-bottom: 10px;
    &.active{
        border-color: #7FA2FE;
    }
}
:deep(.van-checkbox__icon .van-icon) {
    width: 16px;
    height: 16px;
    line-height: 16px;
}
:deep(.van-cell){
    padding: 10px 0;
}
:deep(.van-cell){
    &::after{
        position: static;
    }
}
.cell-wrap{
    margin-bottom: 60px;
    &::after{
        position: static;
    }
}

:deep(.code-text) {
    color: #4cb0ff;
}
.code-text.active{
    color: #BABABA;
}
.sub-btn{
    width: 90%;
    color: #fff;
    text-align: center;
    font-size: 16px;
    margin: 10px auto;
    >div{
        background: linear-gradient(90deg, #82C4FD 0%, #9B9BFF 100%);
        box-sizing: border-box;
        height: 47px;
        line-height: 47px;
        padding: 0 10px;
        border-radius: 45px;
    }
}
.agreement-wrap {
    width: 90%;
    margin:  0 auto;
    font-size: 12px;
    display: flex;
    justify-content: center;
    color: #888888;
    >div{
        margin-top: 2px;
    }
    .dw-checkbox{
        width: 30px;
    }
    .text{
        margin-left: 5px;
    }
    .agreement { 
     color: #4cb0ff;
    }
}
:deep(.van-field__button) {
    display: flex;
    align-items: center;

    span {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        margin-right: 10px;
        color: #CCCCCC;
    }
}
</style>
