// 微信的配置
// 引入微信sdk的包
import wx from 'weixin-js-sdk'
import {getConofigParams} from '@/api/login.js'
import {signLink} from '@/utils/index'
class AuthWechat {
	// 当前是否是微信环境
	isWeixin() {
		return navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1;
	}
	/**
	 * 初始化wechat(分享配置)
	 */
	wechat() {
		return new Promise((resolve, reject) => {
			let url = signLink()
			getConofigParams({url}).then(res => {
				if (res.code == 200) {
					wx.config({
						debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
						appId: res.data.appId, // 必填，公众号的唯一标识
						timestamp: res.data.timestamp, // 必填，生成签名的时间戳
						nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
						signature: res.data.signature, // 必填，签名
						jsApiList: [
							'onMenuShareAppMessage',
							'onMenuShareTimeline',
							'chooseWXPay',
							'showOptionMenu',
							"updateAppMessageShareData",
							"hideMenuItems",
							"showMenuItems",
							"onMenuShareTimeline",
							'onMenuShareAppMessage',
						] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
					})
					wx.ready(e => {
						// 微信SDK准备就绪后执行的回调。
						resolve(wx, e)
					})
					wx.error(err => {
						reject(wx, err)
					})
				}
			})
		})
	}
	// 微信分享
	wxShare(shareObj) {
		this.wechat().then((wx) => {
			wx.ready(() => {
				wx.updateAppMessageShareData({
					title: shareObj.title, // 分享标题
					link: shareObj.link, // 分享链接
					desc: shareObj.desc, // 分享描述
					imgUrl: shareObj.imgUrl,
					/* 分享图标 */ 
					success: function () {},
					cancel: function () {}
				});
				/* 	wx.updateTimelineShareData({
						title: shareObj.title, // 分享标题
						link: shareObj.link, // 分享链接
						desc: shareObj.desc, // 分享描述
						imgUrl: shareObj.imgUrl,
						success: function() {},
						cancel: function() {}
					}); */
			})
		})
	}
	/* 微信支付 */
	wxPay(data) {
		this.wechat().then((wx)=>{
			wx.ready(()=>{
				/* 使用 JS-SDK 拉起微信支付 */ 
				wx.chooseWXPay({
					timeStamp: data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
					nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
					package:data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
					signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
					paySign: data.sign, // 支付签名
					success: function (res) {
						// 支付成功后的回调函数
						console.log(res);
					},
					// 支付取消回调函数
					cancel: function (res) {
						console.log(res)
						alert('用户取消支付~')
					},
					fail: function (res) {
						console.log(res);
					}
				});
			})
		})

	}
	/* 禁止分享和复制链接 */
	noWxShare(){
		this.wechat().then((wx)=>{
			wx.ready(()=>{
				wx.hideMenuItems({
					menuList: ["menuItem:share:appMessage","menuItem:openWithQQBrowser","menuItem:copyUrl",'menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:favorite', 'menuItem:share:QZone','menuItem:openWithSafari'] // 要隐藏的菜单项
				});
			})
		})
	}
}

export default new AuthWechat();