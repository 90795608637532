
export function signLink() {
    if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
        window.entryUrl = document.location.href
    }
    return /(Android)/i.test(navigator.userAgent) ? document.location.href : window.entryUrl;
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if (typeof time === 'string') {
            if (/^[0-9]+$/.test(time)) {
                // support "1548221490638"
                time = parseInt(time)
            } else {
                // support safari
                // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                time = time.replace(new RegExp(/-/gm), '/')
            }
        }

        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        return value.toString().padStart(2, '0')
    })
    return time_str
}

export function setPageTitle(title){
        /*     document.title =res.data.pageName */
        document.title = title;
        let iframe = document.createElement('iframe');
    /*     iframe.src = require('); */
        iframe.style.display = 'none';
        let fn = function () {
            setTimeout(function () {
                iframe.removeEventListener('load', fn);
                document.body.removeChild(iframe);
            }, 0);
        };
        iframe.addEventListener('load', fn);
        document.body.appendChild(iframe);
}

export function getUrlCode(name, url) {
	let reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
	let path = url ? url : window.location.href
	if (reg.test(path)) {
		return unescape(RegExp.$2.replace(/\+/g, " "));
	}
	return "";
}